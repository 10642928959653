import { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Divider,
  Button,
  Text,
  Loading,
} from "@nextui-org/react";
import SearchCard from "./SearchCard";
import { AiFillCloseCircle } from "react-icons/ai";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

const SearchModal = (props) => {
  //Manage search input value
  const [inputValue, setInputValue] = useState("");
  //Manage search results (placeholder until redux is implemented!)
  const [allData, setAllData] = useState([]);
  const [result, setResult] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);
  const [error, setError] = useState(false);

  //input change handler
  const onChangeHandler = (event) => {
    setInputValue(event.target.value);
  };

  //useEffect fetchAll
  useEffect(() => {
    setError(false);
    setLoading(true);
    fetch("https://sca-token-api.vercel.app/orders")
      .then((res) => {
        if (!res.ok) {
          setError(true);
        }
        return res.json();
      })
      .then((data) => {
        const newData = data
          .map((doc) => {
            return doc.products
              .map((subDoc) => {
                return subDoc.codes.map((sbDoc) => {
                  return {
                    ...subDoc,
                    codes: sbDoc,
                    date: doc.date,
                    orderName: doc.name,
                    orderId: doc._id,
                  };
                });
              })
              .flat();
          })
          .flat();
        setAllData(newData);
        setResult(newData);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  //Run useEffect after waiting for 500 ms to prevent request spam
  useEffect(() => {
    const timeoutId = setTimeout(() => filters(), 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dateFilter, inputValue]);

  const filters = () => {
    if (allData.length > 0) {
      setLoading(true);
      let copy = [...allData];

      if (inputValue !== "") {
        const regex = new RegExp(inputValue, "i");
        copy = copy.filter((doc) => {
          return (
            regex.test(doc.name) ||
            regex.test(doc.orderName) ||
            regex.test(doc.codes)
          );
        });
      }

      if (dateFilter !== null && copy.length > 0) {
        copy = copy.filter((doc) => {
          return (
            doc.date > new Date(dateFilter[0]).toISOString() &&
            doc.date < new Date(dateFilter[1]).toISOString()
          );
        });
      }

      setResult(copy);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.visible}
      onClose={props.onClose}
      aria-label="Search"
      fullScreen
      scroll
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal.Header
        css={{
          p: "10px",
          mw: "550px",
          w: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="searchActions">
          <Input
            type="text"
            css={{ w: "100%", mr: "5px" }}
            placeholder="Start searching here..."
            aria-label="Search Input"
            onChange={onChangeHandler}
            clearable
            animated={false}
          />
          <Button auto color="error" onClick={props.onClose}>
            <AiFillCloseCircle />
          </Button>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body
        css={{
          p: "10px",
          // mw: "550px",
          w: "100%",
          height: "100%",
        }}
      >
        <div className="searchResult">
          <div className="dateFilter">
            <DateRangePicker value={dateFilter} onChange={setDateFilter} />
          </div>
          {error && (
            <Text
              css={{
                br: "20px",
                py: "5px",
                px: "10px",
                bgColor: "$red100",
                textAlign: "center",
              }}
              color="$red700"
              size="14px"
            >
              An error occured while trying to retrieve data! Please go back and
              try again.
            </Text>
          )}
          {loading ? (
            <Loading type="spinner" />
          ) : (
            <AutoSizer className="autoSizer">
              {({ width, height }) => (
                <FixedSizeList
                  height={height}
                  itemSize={90}
                  width={width}
                  itemCount={result.length}
                  itemData={{ result }}
                  className="reactWindow"
                  // style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SearchModal;

const Row = ({ data, index, style }) => {
  const doc = data?.result[index];

  return (
    <div style={style} className="linkCards">
      <SearchCard onClose={() => console.log("yada")} doc={doc} key={index} />
    </div>
  );
};
