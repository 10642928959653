import { useState, useRef, useMemo } from "react";
import {
  Modal,
  Text,
  Input,
  Button,
  Divider,
  Textarea,
} from "@nextui-org/react";
import useInput from "../hooks/useInput";
import {
  AiFillCloseCircle,
  AiOutlinePlus,
  AiOutlineBarcode,
} from "react-icons/ai";
import NewScanner from "./scanner/NewScanner";

const selctValues = [
  { title: "iPhone", value: "iPhone" },
  { title: "iPad", value: "iPad" },
  { title: "iWatch", value: "iWatch" },
  { title: "AirPods", value: "Airpods" },
  { title: "Android", value: "Android" },
  { title: "Other", value: "Other" },
];

const desc = {
  iWatch: [
    "40 - GPS",
    "40 - GPS+CELLULAR",
    "41 - GPS",
    "41 - GPS+CELLULAR",
    "44 - GPS",
    "44 - GPS+CELLULAR",
    "45 - GPS",
    "45 - GPS+CELLULAR",
    "49 - GPS",
    "49 - GPS+CELLULAR",
  ],
  Airpods: ["Default"],
};

const AddModal = (props) => {
  const [imeis, setImeis] = useState([]);
  const [category, setCategory] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [scanVisible, setScanVisible] = useState(false);
  const [results, setResults] = useState("");
  const scanCodeRef = useRef();

  const {
    inputValue: detailValue,
    error: detailError,
    isValid: detailValid,
    inputHandler: detailHandler,
    blurHandler: detailBlur,
    clearInput: cleardetail,
  } = useInput((value) => value !== "");

  const {
    inputValue: nameValue,
    error: nameError,
    isValid: nameValid,
    inputHandler: nameHandler,
    blurHandler: nameBlur,
    clearInput: clearName,
  } = useInput((value) => value !== "");

  const {
    inputValue: priceValue,
    error: priceError,
    isValid: priceValid,
    inputHandler: priceHandler,
    blurHandler: priceBlur,
    clearInput: clearPrice,
  } = useInput((value) => value !== "");

  const {
    inputValue: imeiValue,
    error: imeiError,
    isValid: imeiValid,
    inputHandler: imeiHandler,
    blurHandler: imeiBlur,
    clearInput: clearImeis,
  } = useInput((value) => value !== "");

  const data = useMemo(() => {
    let items = [];

    if (category === "iPhone") {
      let regex = new RegExp("iPhone", "i");
      items = props?.prods?.iphones?.filter((doc) => regex.test(doc?.name));
    } else if (category === "iPad") {
      let regex = new RegExp("iPad", "i");
      items = props?.prods?.iphones?.filter((doc) => regex.test(doc?.name));
    } else if (category === "Android") {
      let regex = new RegExp("iPhone", "i"),
        regex2 = new RegExp("iPad", "i");
      items = props?.prods?.iphones?.filter(
        (doc) => !regex.test(doc?.name) && !regex2.test(doc?.name)
      );
    } else if (category === "Airpods") {
      items = props?.prods?.ipods;
    } else {
      items = props?.prods?.iwatches;
    }

    return { items };
  }, [category]);

  const variantsMemo = useMemo(() => {
    let variants = [];

    if (nameValue !== "") {
      if (["iPhone", "iPad", "Android"]?.includes(category)) {
        let doc = props?.prods?.iphones?.find((doc) => doc?.name === nameValue);
        variants = doc?.variants?.map((doc) => doc?.storage);
      } else if (category === "iWatch") {
        let doc = props?.prods?.iwatches?.find(
          (doc) => doc?.name === nameValue
        );
        variants = doc?.variants?.map((doc) => `${doc.size} - ${doc?.type}`);
      } else {
        variants = ["default"];
      }
    }

    return { variants };
  }, [nameValue, detailValue]);

  const pushImeiHandler = () => {
    const newImei = [...imeis, imeiValue];
    setImeis(newImei);
    return clearImeis();
  };

  const onDetected = (text, result) => {
    setResults(text);
  };

  const pushScanValue = (value) => {
    const newImei = [...imeis, value];
    setImeis(newImei);
    return clearImeis();
  };

  const removeImeiHandler = (index) => {
    const newImeis = [...imeis];
    let splicedImeis;
    if (newImeis.length == 1) {
      splicedImeis = [];
    } else {
      splicedImeis = newImeis.filter((doc, i) => {
        return index !== i;
      });
    }
    return setImeis(splicedImeis);
  };

  const closeHandler = () => {
    setImeis([]);
    clearName();
    clearPrice();
    cleardetail();
    return props.closeHandler();
  };

  const toggleScanner = () => {
    setResults([]);
    setScanVisible(!scanVisible);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (
      nameValid &&
      detailValid &&
      priceValid &&
      imeis.length > 0 &&
      category !== ""
    ) {
      setIsValid(true);
      props.addToProduct({
        category,
        name: nameValue,
        details: detailValue,
        price: priceValue,
        codes: imeis,
      });
      return closeHandler();
    }

    return setIsValid(false);
  };

  return (
    <Modal
      fullScreen
      open={props.visible}
      onClose={closeHandler}
      preventClose
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal.Header
        css={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          mw: "550px",
          w: "100%",
        }}
      >
        <Button auto size="sm" rounded color="error" onClick={closeHandler}>
          Close
        </Button>
        <Text>Add IMEI</Text>
        <Button
          disabled={
            nameValid &&
            detailValid &&
            priceValid &&
            imeis.length > 0 &&
            category !== ""
              ? false
              : true
          }
          type="submit"
          auto
          rounded
          size="sm"
          form="addForm"
        >
          Save
        </Button>
      </Modal.Header>
      <Divider />
      <Modal.Body css={{ w: "100%", mw: "550px" }}>
        <form className="imeiForm" onSubmit={submitHandler} id="addForm">
          <div className="selectCustom" style={{ marginTop: 10 }}>
            <select
              id="select"
              onChange={(event) => {
                setCategory(event.target.value);
                clearName();
                cleardetail();
              }}
              aria-label="Select Category"
              defaultValue={""}
            >
              <option disabled value="">
                Select a category
              </option>
              {selctValues.map((doc, index) => {
                return (
                  <option key={index} value={doc.value}>
                    {doc.title}
                  </option>
                );
              })}
            </select>
          </div>
          {category === "Other" || !category ? (
            <>
              <Input
                label={nameError ? "Product Name Required *" : "Product Name"}
                placeholder="Enter Product Name"
                bordered
                onChange={nameHandler}
                onBlur={nameBlur}
                color={nameError ? "error" : "default"}
                value={nameValue}
                aria-label="Name"
              />
              <Input
                label={detailError ? "Details Required *" : "Details"}
                placeholder="Enter Details"
                bordered
                onChange={detailHandler}
                onBlur={detailBlur}
                color={detailError ? "error" : "default"}
                value={detailValue}
                aria-label="Detail"
              />
            </>
          ) : (
            <>
              <div style={{ marginTop: 10 }}>
                <p style={{ fontSize: 14, paddingLeft: 5, marginBottom: 2.5 }}>
                  Product Name
                </p>
                <div className="selectCustom">
                  <select
                    id="select"
                    onChange={nameHandler}
                    aria-label="Select Product"
                    defaultValue={""}
                    value={nameValue}
                  >
                    <option disabled value="">
                      Select a product
                    </option>
                    {data?.items?.map((doc, index) => {
                      return (
                        <option key={index} value={doc.name}>
                          {doc.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <p style={{ fontSize: 14, paddingLeft: 5, marginBottom: 2.5 }}>
                  Details
                </p>
                <div className="selectCustom">
                  <select
                    id="select"
                    onChange={detailHandler}
                    aria-label="Select Details"
                    defaultValue={""}
                    value={detailValue}
                  >
                    <option disabled value="">
                      Select a variant
                    </option>
                    {[
                      ...(category === "Airpods"
                        ? desc.Airpods
                        : variantsMemo.variants),
                    ]?.map((doc, index) => {
                      return (
                        <option key={index} value={doc}>
                          {doc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </>
          )}
          <Input
            type="number"
            label={priceError ? "Price Required *" : "Price"}
            placeholder="Enter Price"
            bordered
            onChange={priceHandler}
            onBlur={priceBlur}
            color={priceError ? "error" : "default"}
            value={priceValue}
            aria-label="Price"
          />
          <div className="imeiAdd">
            <Input
              css={{ w: "90%" }}
              type="text"
              placeholder="Enter Code"
              bordered
              onChange={imeiHandler}
              onBlur={imeiBlur}
              value={imeiValue}
              aria-label="Imei"
            />
            <Button auto flat color="warning" rounded onClick={toggleScanner}>
              <AiOutlineBarcode />
            </Button>
            <Button
              disabled={imeiValid ? false : true}
              auto
              flat
              color="warning"
              rounded
              onClick={pushImeiHandler}
            >
              <AiOutlinePlus />
            </Button>
          </div>
          <div className="addedImeis">
            {imeis.length > 0 &&
              imeis.map((doc, index) => {
                return (
                  <div key={doc} className="imeis">
                    <Text b css={{ letterSpacing: "1px" }} size="14px">
                      {doc}
                    </Text>
                    <Button
                      auto
                      size="xs"
                      flat
                      color="error"
                      onClick={() => removeImeiHandler(index)}
                    >
                      <AiFillCloseCircle />
                    </Button>
                  </div>
                );
              })}
          </div>
          {scanVisible && (
            <div>
              <div className="scanActions">
                <Input
                  aria-label="Scan Result"
                  type="text"
                  value={results}
                  bordered
                  ref={scanCodeRef}
                  css={{ w: "90%" }}
                />
                <Button
                  color="warning"
                  flat
                  rounded
                  auto
                  disabled={results !== "" ? false : true}
                  onClick={() => pushScanValue(scanCodeRef.current.value)}
                >
                  <AiOutlinePlus />
                </Button>
              </div>
              <div className="scanner">
                <NewScanner
                  fps={60}
                  qrbox={300}
                  disableFlip={false}
                  qrCodeSuccessCallback={onDetected}
                />
              </div>
            </div>
          )}
          {!isValid && (
            <Text color="error" b size="14px" css={{ alignSelf: "center" }}>
              Please enter all fields to proceed!
            </Text>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
