import { Html5QrcodeScanner } from "html5-qrcode";
import React from "react";

const qrcodeRegionId = "html5qr-code-full-region";

class NewScanner extends React.Component {
  render() {
    return <div id={qrcodeRegionId} />;
  }

  componentWillUnmount() {
    this.html5QrcodeScanner.clear().catch((error) => {
      console.error("Failed to clear html5QrcodeScanner. ", error);
    });
  }

  componentDidMount() {
    function createConfig(props) {
      var config = {};
      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }
      return config;
    }

    var config = createConfig(this.props);
    var verbose = this.props.verbose === true;

    if (!this.props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    this.html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      { fps: 60, qrbox: { width: 250, height: 70 }, aspectRatio: 1.777778 },
      verbose
    );
    this.html5QrcodeScanner.render(
      this.props.qrCodeSuccessCallback,
      this.props.qrCodeErrorCallback
    );
  }
}

export default NewScanner;
