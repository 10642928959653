import { Card, Text, Divider } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";

const SearchCard = (props) => {
  const navigate = useNavigate();

  return (
    <Card
      variant="flat"
      css={{
        marginBottom: "0.5rem",
        cursor: "pointer",
        w: "100%",
        mw: "550px",
        margin: 'auto'
      }}
      className="cardShadow"
      isPressable
      // style={{ ...props.style, top: `${parseFloat(props.style.top) + 5}px` }}
      onClick={() => navigate(`/orders/${props.doc?.orderId}`)}
    >
      <Card.Header
        css={{ display: "inline-flex", justifyContent: "space-between" }}
      >
        <Text b size="13px">
          {props.doc?.orderName}
        </Text>
        <Text b size="13px">
          {props.doc?.date?.slice(0, 10)}
        </Text>
      </Card.Header>
      <Divider />
      <Card.Body
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          w: "100%",
          py: "10px",
        }}
      >
        <Text b size="13px">
          {props.doc?.name}
        </Text>
        <Text b size="13px">
          {props.doc?.codes}
        </Text>
      </Card.Body>
    </Card>
  );
};

export default SearchCard;
