import { Text, Card, Button } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowWide from "../hooks/useWindowWide";
import { AiOutlineLineChart, AiOutlinePlus } from "react-icons/ai";
import { Fragment } from "react";

const options = [
  {
    icons: <AiOutlineLineChart className="iconAdjust" />,
    value: "All Data",
    link: "all",
  },
  {
    icons: <AiOutlinePlus className="iconAdjust" />,
    value: "New Customer",
    link: "new-customer",
  },
];

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname;
  const currentWidth = useWindowWide(860);

  return (
    <Card variant="flat">
      {currentWidth ? (
        <Fragment>
          <Card.Body
            css={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {options.map((doc, index) => {
              return (
                <Text
                  className={
                    active === `/dashboard/${doc.link}`
                      ? "sidebarOption sidebarOptionActive"
                      : "sidebarOption"
                  }
                  key={index}
                  css={{
                    br: "20px",
                    py: "5px",
                    px: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/dashboard/${doc.link}`);
                  }}
                  size="14px"
                >
                  {doc.icons}&nbsp;
                  {doc.value}
                </Text>
              );
            })}
          </Card.Body>
          <Card.Divider />
          <Card.Footer>
            <Button
              rounded
              auto
              color="success"
              size="sm"
              onClick={() => navigate("/new-order")}
            >
              <AiOutlinePlus />
              &nbsp; New Order
            </Button>
          </Card.Footer>
        </Fragment>
      ) : (
        <Card.Body
          css={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {options.map((doc, index) => {
            return (
              <Text
                className={
                  active === `/dashboard/${doc.link}`
                    ? "sidebarOption sidebarOptionActive"
                    : "sidebarOption"
                }
                key={index}
                css={{
                  br: "20px",
                  py: "5px",
                  px: "10px",
                  cursor: "pointer",
                  w: "fit-content",
                }}
                onClick={() => {
                  navigate(`/dashboard/${doc.link}`);
                }}
                size="14px"
              >
                {doc.icons}
              </Text>
            );
          })}
          <Text css={{ mx: "10px" }}>|</Text>
          <Button
            rounded
            auto
            color="success"
            size="sm"
            onClick={() => navigate("/new-order")}
            css={{ alignSelf: "flex-end" }}
          >
            <AiOutlinePlus />
            &nbsp; New Order
          </Button>
        </Card.Body>
      )}
    </Card>
  );
};

export default Sidebar;
