import { Loading } from "@nextui-org/react";
import OrderTable from "./OrderTable";

const DashOrder = (props) => {
  return (
    <div className="customerDiv">
      {props.loading && props?.list?.length <= 0 ? (
        <Loading type="spinner" />
      ) : (
        <OrderTable
          list={props.list || []}
          asc={props.asc}
          sortFunction={props.sortFunction}
          setValue={props.setValue}
          value={props.value}
          refetch={props.refetch}
        />
      )}
    </div>
  );
};

export default DashOrder;
