import { Card, Text, Divider, Button } from "@nextui-org/react";
import { useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import DownloadButton from "./download-button";

const PrintCard = (props) => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);

  //Download Product PDF
  const downloadPDf = async (id, imei, product) => {
    setLoad(true);
    const res = await fetch(
      `https://sca-token-api.vercel.app/pdf-single/${id}/${imei}`
    );
    if (!res.ok) {
      const message = `An error has occured: ${res.status}`;
      throw new Error(message);
    }

    const blobz = await res.blob();
    if (blobz.size <= 0) {
      const message = `An error has occured: ${res.status}`;
      throw new Error(message);
    }

    const url = window.URL.createObjectURL(
      new Blob([blobz], { type: "application/pdf" })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = `${product}.pdf`; // Set the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoad(false);
  };

  return (
    <Card
      variant="flat"
      css={{
        marginBottom: "0.5rem",
        cursor: "pointer",
        w: "100%",
        mw: "550px",
        margin: "auto",
      }}
      className="cardShadow"
      //   isPressable
      // style={{ ...props.style, top: `${parseFloat(props.style.top) + 5}px` }}
      //   onClick={() => navigate(`/orders/${props.doc?.orderId}`)}
    >
      <Card.Header
        css={{
          display: "inline-flex",
          justifyContent: "space-between",
          py: "5px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text b size="13px">
            {props.doc?.orderName}
          </Text>
          <Text b size="13px">
            {props.doc?.date?.slice(0, 10)}
          </Text>
        </div>
        <DownloadButton
          link={`https://sca-token-api.vercel.app/pdf-single/${props.doc?._id}/${props.doc?.codes}`}
          product={props.doc?.orderName}
          id={props.doc?._id}
        />
      </Card.Header>
      <Divider />
      <Card.Body
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          w: "100%",
          py: "5px",
        }}
      >
        <Text b size="13px">
          {props.doc?.name}
        </Text>
        <Text b size="13px">
          {props.doc?.codes}
        </Text>
      </Card.Body>
    </Card>
  );
};

export default PrintCard;
