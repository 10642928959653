import { Button, Loading, Spinner } from "@nextui-org/react";
import { useState } from "react";
import { AiFillPrinter } from "react-icons/ai";

const DownloadButton = ({ link, id, product }) => {
  const [load, setLoad] = useState(false);

  //Download Product PDF
  const downloadPDf = async () => {
    setLoad(true);
    const res = await fetch(link);
    if (!res.ok) {
      const message = `An error has occured: ${res.status}`;
      throw new Error(message);
    }

    const blobz = await res.blob();
    if (blobz.size <= 0) {
      const message = `An error has occured: ${res.status}`;
      throw new Error(message);
    }

    const url = window.URL.createObjectURL(
      new Blob([blobz], { type: "application/pdf" })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = `${product}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoad(false);
  };

  return (
    <Button
      onClick={() => downloadPDf()}
      size="xs"
      rounded
      color="success"
      auto
      flat
      disabled={load}
    >
      {load ? <Loading size="xs" /> : <AiFillPrinter />}
    </Button>
  );
};

export default DownloadButton;
